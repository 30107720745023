<script>
export default {
  form: {
    utm_source: {
      type: 'text',
      label: 'Source (obligatoire)',
      tooltipHTML: "Le canal d'acquisition : <br> YouTube, liste-email, Instagram, Pinterest, publicité...",
      tooltipAttrs: {
        multilined: true,
      },
      column: 'is-12',
      inputAttrs: {
        required: true,
        placeholder: '',
      },
    },
    utm_medium: {
      type: 'text',
      label: 'Medium',
      tooltipHTML: 'Le type de contenu : <br> vidéo, mail, article, pin, bannière, bouton...',
      tooltipAttrs: {
        multilined: true,
      },
      column: 'is-12',
      inputAttrs: {
        placeholder: '',
      },
    },
    utm_campaign: {
      type: 'text',
      label: 'Campagne',
      tooltipHTML: 'Le nom de la campagne : <br> code-promo-2015, mail-du-08-mars-2025, nom-de-votre-video...',
      tooltipAttrs: {
        multilined: true,
      },
      column: 'is-12',
      inputAttrs: {
        placeholder: '',
      },
    },
    // utm_id: {
    //   type: 'text',
    //   label: 'Identifiant',
    //   tooltipHTML: 'Un identifiant technique',
    //   tooltipAttrs: {
    //     multilined: true,
    //   },
    //   column: 'is-4',
    //   inputAttrs: {
    //     placeholder: '',
    //   },
    // },
    // utm_term: {
    //   type: 'text',
    //   label: 'Terme',
    //   tooltipHTML: "Le terme utilisé si vous voulez être plus précis : <br> texte d'un bouton, mots-clés d'une pub...",
    //   tooltipAttrs: {
    //     multilined: true,
    //   },
    //   column: 'is-4',
    //   inputAttrs: {
    //     placeholder: '',
    //   },
    // },
    // utm_content: {
    //   type: 'text',
    //   label: 'Contenu',
    //   tooltipHTML: 'Un contenu associé si vous voulez être plus précis : <br> texte de votre pub, ...',
    //   tooltipAttrs: {
    //     multilined: true,
    //   },
    //   column: 'is-4',
    //   inputAttrs: {
    //     placeholder: '',
    //   },
    // },
  },
  data() {
    return {
      isFetching: false,
      utmURL: null,
      utmURLType: null,
      utmCustomURL: null,
      utm: {
        utm_source: null,
        utm_medium: null,
        utm_campaign: null,
        utm_id: null,
        utm_term: null,
        utm_content: null,
      },
    };
  },
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
    trainings() {
      return this.$store.state.trainings.list;
    },
    trainingsData() {
      if (this.trainings && this.trainings.data) {
        return this.trainings.data.filter((one) => one.status !== 'DRAFT');
      }

      return null;
    },
    bundles() {
      return this.$store.state.bundles.list;
    },
    bundlesData() {
      if (this.bundles && this.bundles.data) {
        return this.bundles.data.filter((bundle) => bundle.status !== 'DRAFT');
      }

      return null;
    },
  },
  created() {
    this.isFetching = true;
    Promise.all([
      this.$store.dispatch('trainings/fetch')
        .then(() => {
          const { formation: uuid } = this.$route.query;

          if (uuid) {
            const utmURLType = {};
            utmURLType.buyable = this.trainings.data.find((one) => one.uuid === uuid);
            utmURLType.buyable_type = this.$constants.BUYABLE_TYPE.TRAINING;
            this.utmURLType = utmURLType;
          }
        }),
      this.$store.dispatch('bundles/fetch')
        .then(() => {
          const { pack: uuid } = this.$route.query;

          if (uuid) {
            const utmURLType = {};
            utmURLType.buyable = this.bundles.data.find((one) => one.uuid === uuid);
            utmURLType.buyable_type = this.$constants.BUYABLE_TYPE.BUNDLE;
            this.utmURLType = utmURLType;
          }
        }),
    ])
      .finally(() => (this.isFetching = false));
  },
  methods: {
    generateURL() {
      this.utmURL = null;

      if (!this.utmURLType || !this.$refs.form.checkValidity()) {
        this.utmURL = null;
      }

      let url;
      const { protocol } = window.location;

      if (this.utmURLType === 'CUSTOM') {
        if (!this.utmCustomURL) {
          this.utmURL = null;
        }

        url = this.utmCustomURL;
      } else if (this.utmURLType === 'HOME') {
        url = `${protocol}${this.$store.getters['auth/url']}`;
      } else {
        const { slug } = this.utmURLType.buyable;
        const type = this.utmURLType.buyable_type === this.$constants.BUYABLE_TYPE.BUNDLE
          ? 'packs' : 'formations';
        url = `${protocol}${this.$store.getters['auth/url']}/${type}/${slug}`;
      }

      const utmString = Object.keys(this.utm)
        .reduce((prev, acc) => {
          if (this.utm[acc] != null && this.utm[acc] !== '') {
            return `${prev}&${acc}=${encodeURIComponent(this.utm[acc]).replace(/(%20)+/g, '+')}`;
          }

          return prev;
        }, '');

      this.utmURL = `${url}${url.includes('?') ? '&' : '?'}${utmString.substring(1)}`.trim();
    },
  },
};
</script>

<template>
  <AppFeatureWrapper>
    <template #title>
      Créez vos liens de suivi UTM <span class="tag is-primary is-light mb-3">NOUVEAU</span>
    </template>
    <template #content>
      <p>
        Identifiez l’origine de vos inscriptions grâce à des liens de suivi UTM personnalisés.
      </p>
      <p>
        Teachizy est l'un des premiers outils de formation à proposer cette fonctionnalité.
        Elle vous permet d’analyser et d’optimiser vos canaux d’acquisition.
      </p>
      <RenderlessToggle #default="{ isOpen, toggle }" :open="false">
        <div class="card block">
          <header class="card-header">
            <a
              class="
                card-header-title is-flex is-align-items-center
                is-justify-content-space-between has-text-black
                has-text-weight-medium is-size-7
              "
              @click.prevent="toggle"
            >
              Qu'est-ce qu'un lien de suivi UTM ?
              <b-icon
                class="ml-3"
                :icon="isOpen ? 'chevron-down' : 'chevron-right'"
              />
            </a>
          </header>
          <div v-show="isOpen" class="card-content is-size-8">
            <p>
              Un lien <b-tooltip dashed label="(Urchin Tracking Module)">UTM</b-tooltip>,
              <strong>c’est un lien intelligent qui vous révèle enfin d’où viennent vos inscrits</strong>.
              En créant un lien UTM différent pour chaque canal (emailing, réseaux sociaux, vidéo, QR code…),
              <strong>
                Teachizy vous permet d’identifier l’origine de chaque apprenant,
                directement depuis votre espace.
              </strong>
            </p>
            <p>
              Vous pourrez ainsi repérer les canaux qui convertissent réellement,
              affiner vos campagnes marketing,
              <strong>et concentrer vos efforts sur ce qui fonctionne le mieux</strong>.
              Que vous partagiez vos formations sur Instagram,
              dans une campagne email, via un partenaire ou sur un salon, les liens UTM
              vous offrent <strong>une vision claire et actionnable de votre acquisition</strong> !
            </p>
            <p>
              Teachizy vous permet de générer ces liens facilement, sans connaissance technique,
              pour vous aider à prendre des décisions plus stratégiques et orientées résultats.
            </p>
          </div>
        </div>
      </RenderlessToggle>
      <RenderlessToggle #default="{ isOpen, toggle }" :open="false">
        <div>
          <p class="buttons">
            <b-button tag="a" href="https://aide.teachizy.fr/fr/article/comment-creer-vos-liens-de-suivi-utm-1mwbn38/" target="_blank" rel="noopener noreferrer">
              Voir la page d'aide
            </b-button>
            <b-button type="is-primary" @click="toggle">
              Regarder la démo
            </b-button>
          </p>

          <b-modal :width="728" :active="isOpen" @close="toggle">
            <div class="box image is-16by9">
              <iframe class="w-full h-full pabsolute-0" width="2100" height="907" src="https://www.youtube.com/embed/-94mYFW0btU" title="Tutoriel UTM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
          </b-modal>
        </div>
      </RenderlessToggle>
    </template>

    <template #form>
      <b-skeleton v-if="isFetching" height="560" />
      <form v-else ref="form" class="box" @submit.prevent="generateURL()">
        <div class="columns is-multiline">
          <div class="column is-12">
            <p v-if="(!trainingsData || !trainingsData.length) && (!bundlesData || !bundlesData.length)">
              Vous n'avez aucun produit publié à intégrer pour le moment.
            </p>
            <b-field v-else label="Pour quel produit souhaitez-vous créer un lien de suivi UTM ?">
              <b-select v-model="utmURLType" required expanded>
                <option value="">Choisissez...</option>
                <option value="CUSTOM">URL personnalisée</option>
                <option value="HOME">Page d'accueil</option>
                <template v-if="trainingsData.length">
                  <option disabled>-- Formations --</option>
                  <option
                    v-for="training in trainingsData"
                    :key="training.id"
                    :value="{ buyable: training, buyable_type: $constants.BUYABLE_TYPE.TRAINING }">
                    {{ training.name }}
                  </option>
                </template>
                <template v-if="bundlesData && bundlesData.length">
                  <option disabled>-- Packs --</option>
                  <option
                    v-for="bundle in bundlesData"
                    :key="bundle.id"
                    :value="{ buyable: bundle, buyable_type: $constants.BUYABLE_TYPE.BUNDLE }">
                    {{ bundle.name }}
                  </option>
                </template>
              </b-select>
            </b-field>
          </div>
          <div v-if="utmURLType == 'CUSTOM'" class="column is-12">
            <b-field label="Entrez une URL" label-for="custom-url">
              <b-input type="url" required v-model="utmCustomURL" />
            </b-field>
          </div>
          <div v-for="(field, k) in $options.form" :key="k" class="column" :class="field.column">
            <BaseField v-model="utm[k]" :field="field" />
          </div>
        </div>

        <b-field class="has-text-right">
          <b-button type="is-primary" native-type="submit" @click="utmURL = null">
            Générer mon lien de suivi UTM
          </b-button>
        </b-field>
      </form>

      <div v-if="utmURL" class="box is-relative content is-info break-all pr-10">
        <h4 class="is-size-6">
          Votre lien de suivi
        </h4>
        <p>
          {{ utmURL }}
        </p>
        <b-tooltip
          class="is-absolute"
          style="right: 10px; top: 10px;"
          label="Copier"
          position="is-left">
          <b-button
            size="is-small"
            icon-left="clipboard"
            v-clipboard:copy="utmURL"
            v-clipboard:success="() => $copySuccess()"
          />
        </b-tooltip>
      </div>
    </template>
  </AppFeatureWrapper>
</template>
