<script>
import UTMBuilder from '@app/components/UTMBuilder.vue';
import StoreAnalyticsForm from '@app/components/StoreAnalyticsForm.vue';
import StoreCustomCodeForm from '@app/components/StoreCustomCodeForm.vue';

export default {
  components: {
    UTMBuilder,
    StoreAnalyticsForm,
    StoreCustomCodeForm,
  },
  head: {
    title: 'Suivi / Tracking - Outils Marketing',
  },
};
</script>

<template>
  <div>
    <UTMBuilder />
    <StoreAnalyticsForm />
    <StoreCustomCodeForm />
  </div>
</template>
